@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full;
  }

  section {
    @apply relative py-16 lg:py-20;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  footer a:hover {
    @apply underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-headings;
  }
}

@layer components {
  .btn {
    @apply btn-base btn-pseudo bg-site-base text-white [&:not([disabled])]:focus:bg-site-base;
  }

  .btn-default {
    @apply bg-site-base text-white hover:bg-site-base-200;
  }

  .btn-light {
    @apply bg-site-base-200 text-white hover:bg-site-base;
  }

  .btn-white {
    @apply bg-white text-site-base-600 hover:bg-site-base hover:text-white;
  }

  .content {
    @apply content-base content-pseudo content-paragraphs content-headings;
  }
}

@layer utilities {
  .btn-pseudo {
    @apply transition-colors disabled:opacity-75;
  }

  .btn-base {
    @apply py-3 pl-7 pr-7 font-semibold inline-flex rounded-full xl:min-w-[183px] justify-center items-center;
    @apply [&:has(img)]:pr-[2px] [&:has(img)]:py-[2px] [&_img]:[&:has(img)]:ml-4 [&_img]:hover:[&:has(img)]:scale-95 [&_img]:[&:has(img)]:transition-transform [&_img]:[&:has(img)]:will-change-transform;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content-pseudo {
    @apply children-a:underline hover:children-a:text-site-base;
  }

  .content-base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content-paragraphs {
    @apply max-sm:children-p:text-lg children-p:mb-4 children-p:font-light children-p:text-site-base children-p:!leading-[158.33%] lg:children-p:text-lg text-base;
  }

  .content-headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-site-base;
  }

  .content-gradient {
    @apply children-p:text-white children-headings:text-white;
  }

  .bg-gradient {
    @apply bg-gradient-to-br from-site-base via-[40%] via-site-base-400 to-[90%] to-[#B9D5FA];
  }

  .font-outline-white {
    -webkit-text-stroke: 1.5px white;
    color: transparent;
  }

  .font-outline-site-base {
    -webkit-text-stroke: 1.5px #44277c;
    color: transparent;
  }

  .bg-glass {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .bg-glass-gradient {
    @apply bg-gradient-to-r from-white/70 to-white/20;

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .bg-glass-gradient-alt {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(109deg, rgba(255, 255, 255, 0.77) 10.88%, rgba(255, 255, 255, 0.04) 94.69%);
    backdrop-filter: blur(5px);
  }

  .bg-glass-gradient-alt-chosen {
    border: 5px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(109deg, rgba(255, 255, 255, 0.77) 10.88%, rgba(255, 255, 255, 0.04) 94.69%);
    backdrop-filter: blur(5px);
  }

  .content .wp-block-button {
    @apply flex justify-center;
  }
  .content .wp-block-button > a {
    @apply btn-base btn-pseudo !bg-site-base text-white [&:not([disabled])]:focus:bg-site-base no-underline !text-base text-center;
  }
  .content ul {
    @apply list-disc ml-5 pb-4 text-site-base lg:text-lg text-base font-light;
  }

  .content ol {
    @apply list-decimal ml-5 pb-4 text-site-base lg:text-lg text-base font-light;
  }

  .content figure {
    @apply relative mb-4 children-img:rounded-lg;
  }

  .content iframe {
    @apply max-w-full h-auto aspect-video;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-site-base h-[6px] rounded-r-full;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn;
}

.form-duuf-group {
  @apply mb-3;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex-center;
}

.form-contact .form-duuf-label {
  @apply block font-bold mb-1 pl-4;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-[20px] py-[6px] px-4 border-black text-site-base w-full;
}

.form-duuf-select__control {
  @apply !rounded-full !h-[44px] !py-[3px];
}

.form-duuf-select__menu {
  @apply !text-black;
}

.form-duuf-textarea,
.form-duuf-group-textarea {
  @apply min-h-[230px];
}

.form-duuf-group-textarea {
  @apply flex-col flex;
}

.form-duuf-group {
  @apply relative mb-2;
}
.form-duuf-group:last-child {
  @apply mb-0;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn btn-white py-1 px-2;
}

.form-duuf-checkbox {
  @apply w-4 h-4;
}

.form-contact .form-duuf-error {
  @apply absolute right-0 top-2 !text-site-light !font-light text-sm;
}

.form-contact .form-duuf-textarea {
  @apply py-3;
}

.form-contact .form-duuf-label {
  @apply pl-0;
}

.form-contact .form-duuf-group {
  @apply mb-5;
}

.form-contact .form-duuf-error-consent {
  @apply -top-6;
}

.form-contact .form-duuf-input {
  @apply px-4 py-[10px] rounded-full;
}

.form-contact .form-duuf-checkbox-label {
  @apply font-bold ml-3 text-black;
}

.form-contact .form-duuf-error {
  @apply text-red-500 pl-4 mb-1 -mt-1 font-bold;
}

.form-newsletter .form-duuf-label {
  @apply font-normal;
}

.form-newsletter .form-duuf-checkbox-label {
  @apply font-normal;
}

.form-newsletter .form-duuf-error {
  @apply block p-2 rounded-sm text-gray-200 absolute right-0 -top-1 text-sm;
}

.form-newsletter .form-duuf-group-consent {
  @apply mt-6 mb-4;
}

.form-newsletter .form-duuf-error-consent {
  @apply relative top-2 mb-4;
}

.form-newsletter .form-duuf-group-consent > .items-center {
  @apply items-start;
}

.form-newsletter .form-duuf-checkbox-consent {
  @apply mr-2 mt-[2px] min-w-[20px] min-h-[20px];
}

.form-newsletter .form-duuf-submit button {
  @apply w-full;
}

.form-grid > form {
  @apply grid sm:grid-cols-2 gap-8;
}

/* Custom */

.swiper-button-disabled {
  @apply opacity-50;
}

/* Blog helpers */

.wp-block-button {
  @apply block my-4 lg:my-12;
}

#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogPoweredByText {
  display: none !important;
}
